import React, { useEffect, useState } from "react";
import PageHeader from "../components/common/PageHeader";
import Layout from "../components/common/Layout";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import CenteredSection from "../components/common/CenteredSection";
import { getImage } from "gatsby-plugin-image";
import ContactUs from "../components/common/ContactUs";
import ImageGallery from "../components/common/ImageGallery";
import TabSection from "../components/common/TabSection";
import Seo from "../components/default/seo";
import BenchmarkPlanningIcon from "../images/icons/benchmark_planning.svg";
import CultivationPlanningIcon from "../images/icons/cultivation_planning.svg";
import DataAcquisitionIcon from "../images/icons/data_acquisition.svg";
import DataProcessingIcon from "../images/icons/data_processing.svg";
import IntegrationIcon from "../images/icons/integration.svg";
import LaborPlanningIcon from "../images/icons/labor_planning.svg";
import MethodsPlanningIcon from "../images/icons/methods_planning.svg";
import BenchmarkPlanningSelectedIcon from "../images/icons/benchmark_planning_selected.svg";
import CultivationPlanningSelectedIcon from "../images/icons/cultivation_planning_selected.svg";
import DataAcquisitionSelectedIcon from "../images/icons/data_acquisition_selected.svg";
import DataProcessingSelectedIcon from "../images/icons/data_processing_selected.svg";
import IntegrationSelectedIcon from "../images/icons/integration_selected.svg";
import LaborPlanningSelectedIcon from "../images/icons/labor_planning_selected.svg";
import MethodsPlanningSelectedIcon from "../images/icons/methods_planning_selected.svg";

const tabs = [
  {
    title: "Cultivation Planning",
    icon: CultivationPlanningIcon,
    iconSelected: CultivationPlanningSelectedIcon,
    img: "cultivation",
    text: "cultivationPlanningText",
  },
  {
    title: "Methods Planning",
    icon: MethodsPlanningIcon,
    iconSelected: MethodsPlanningSelectedIcon,
    img: "methods",
    text: "methodsPlanningText",
  },
  {
    title: "Labor Planning",
    icon: LaborPlanningIcon,
    iconSelected: LaborPlanningSelectedIcon,
    img: "labor",
    text: "laborPlanningText",
  },
  {
    title: "Benchmark Planning (KPIs)",
    icon: BenchmarkPlanningIcon,
    iconSelected: BenchmarkPlanningSelectedIcon,
    img: "benchmark",
    text: "benchmarkPlanningText",
  },
  {
    title: "Data Acquisition",
    icon: DataAcquisitionIcon,
    iconSelected: DataAcquisitionSelectedIcon,
    img: "acquisition",
    text: "dataAcquisitionText",
  },
  {
    title: "Integration",
    icon: IntegrationIcon,
    iconSelected: IntegrationSelectedIcon,
    img: "integration",
    text: "integrationText",
  },
  {
    title: "Advanced Data Processing",
    icon: DataProcessingIcon,
    iconSelected: DataProcessingSelectedIcon,
    img: "processing",
    text: "advancedDataProcessingText",
  },
];

export default function Solutions({ data }) {
  const [images, setImages] = useState({});
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const images = {};
    for (const edge of data.images.edges) {
      images[edge.node.name] = getImage(edge.node);
    }
    setImages(images);
  }, [data.images]);

  return (
    <Layout>
      <Seo
        title={t("solutionsHeaderTitle")}
        description={t("solutionsHeaderText")}
        lang={language}
      />
      <PageHeader
        title={t("solutionsHeaderTitle")}
        text={t("solutionsHeaderText")}
        img={images.hero}
      />
      <CenteredSection title={t("solutionsIntroTitle")}>
        <p>{t("solutionsIntroText")}</p>
      </CenteredSection>
      <TabSection tabs={tabs} images={images} />
      {/* <ImageGallery images={data.galleryImages} /> */}
      <ContactUs />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "solutions"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "solutions" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    galleryImages: allFile(filter: { relativeDirectory: { eq: "solutions" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 1120
              height: 614
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
